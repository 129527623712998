import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import compose from "recompose/compose";
import {
  Paper,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import MultiSelect from "../../../../../common/MultiSelect";
import { LeaderboardIcon } from "../../../../../common/SvgIcon";
import IndividualBoard from "./IndividualBoard";
import SubmitCancelBtn from "../../../../../common/SubmitCancelBtn";
import TeamBoard from "./TeamBoard";
import BinaryResponseBoard from "./BinaryResponseBoard";
import {
  updateUserSelectedProgram,
  updateUserSelectedEvent,
} from "../../../../../../redux/user";
import Properties from "../../../../../../properties/Properties";
import ApplicationUtil from "../../../../../../utils/ApplicationUtil";
import ApplicationConstants from "../../../../../../constants/ApplicationConstants";

const styles = (theme) => ({
  leaderboard: {
    margin: " auto",

    backgroundColor: "#caf0f8",

    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dropdownStyling: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    // marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      marginBottom: "20px",
    },
  },
  goBtnStyling: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "12px",
    },
  },
  container_top: {
    /* width: 20%; */
    display: "flex",
    alignItems: "center",

    justifyContent: "flex-start",
    margin: 0,
    marginLeft: "5%",
    marginTop: "20px",
  },
  input: {
    marginLeft: "1%",
    maxWidth: "260px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "15%",
      maxWidth: "auto",
    },
  },

  leaderboardTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "rgb(40, 131, 181)",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
    margin: "auto",
  },
  radioStyling: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      justifyContent: "space-evenly",
      flexWrap: "nowrap",
      // flexDirection: "column",
    },
  },
  radioStyle: {
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      marginLeft: "15px",
      paddingLeft: "5px",
      marginRight: "2px",
    },
  },
  labelStyling: {
    "& .MuiTypography-body1": {
      fontSize: "20px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-11px",
      marginRight: 0,
      padding: 0,
      marginTop: "15px",
    },
  },
});
class Leaderboard extends Component {
  constructor(props) {
    super(props);
    const { classes, challengeType, noTitle, width } = this.props;
    this.state = {
      radioValue:
        challengeType?.challengeType === "TEAM" ? "team" : "individual",
      showRunnerGroupGraph: challengeType?.showRunnerGroupGraph,
      message: true,
      programEventList: [],
      selectedProgramEvent: {},
      summeryGeneratedEvents: [],
    };
  }

  handleRadioChange = (event) => {
    event.preventDefault();
    const value = event.target.value;
    this.setState({
      radioValue: value,
    });
  };

  componentDidUpdate = (previousProps) => {
    if (this.props.categoryDTO !== previousProps.categoryDTO) {
      this.setState({
        categoryDTO: this.props.categoryDTO,
      });
    }
    if (
      this.props.challengeType?.challengeType !==
      previousProps?.challengeType?.challengeType
    ) {
      this.setState({
        radioValue:
          this.props.challengeType?.challengeType === "TEAM"
            ? "team"
            : "individual",
        showRunnerGroupGraph: this.props.challengeType?.showRunnerGroupGraph,
      });
    }
  };
  componentDidMount = () => {
    let summeryGeneratedEvents;
    let summeryGeneratedChallenges;
    if (this.props.isProgram) {
      let programDetails = ApplicationUtil.getRunnersProgramDetails();
      if (programDetails?.id) {
        if (programDetails) {
          if (programDetails?.eventDTO) {
            let eventDTO = programDetails?.eventDTO || [];
            summeryGeneratedEvents = eventDTO
              .map((x) => {
                if (x.summaryGenerated) {
                  return x;
                }
              })
              .filter(function (element) {
                return element !== undefined;
              });
          }

          if (programDetails?.programChallenges) {
            let programChallenges = programDetails?.programChallenges || [];
            summeryGeneratedChallenges = programChallenges
              .map((x) => {
                if (x.programChallengeSettings?.summaryGenerated === "true") {
                  return x;
                }
              })
              .filter(function (element) {
                return element !== undefined;
              });
          }

          let allSummeryGeneratedEvents;

          if (summeryGeneratedEvents && summeryGeneratedChallenges) {
            allSummeryGeneratedEvents = [
              ...summeryGeneratedEvents,
              ...summeryGeneratedChallenges,
            ];
          } else if (!summeryGeneratedEvents && summeryGeneratedChallenges) {
            allSummeryGeneratedEvents = summeryGeneratedChallenges;
          } else {
            allSummeryGeneratedEvents = summeryGeneratedEvents;
          }
          const defaultSelection = this.isEventOngoing(
            allSummeryGeneratedEvents
          );
          allSummeryGeneratedEvents = allSummeryGeneratedEvents.filter(
            (event) => event.id !== defaultSelection.id
          );
          allSummeryGeneratedEvents = [defaultSelection].concat(
            allSummeryGeneratedEvents
          );

          //Ankit if no data is present then handle then state doesn't get update.
          if (
            allSummeryGeneratedEvents !== undefined &&
            allSummeryGeneratedEvents.length > 0
          ) {
            let list = allSummeryGeneratedEvents
              .filter((x) => x !== null && x !== undefined)
              .map((x) => {
                return {
                  label: x.name,
                  value: x.id,
                };
              });
            this.setState({
              programEventDTO: allSummeryGeneratedEvents,
              programEventList: list,
              selectedProgramEvent: list[0],
              summeryGeneratedEvents: allSummeryGeneratedEvents,
            });

            //1st obj is current ongoing / last ended challenge
            if (list && list?.length > 0) {
              this.handleChangeEventSubmit(list[0]);
            }
          }
        }
      }
    }
  };
  isEventOngoing = (events) => {
    if (events && events.length > 0) {
      const onGoingEvents = events?.filter((event) =>
        moment().isBetween(
          moment(event?.eventLocalStartDate, "DD-MM-YYYY"),
          moment(event?.eventLocalEndDate, "DD-MM-YYYY").add(1, "day")
        )
      );
      if (onGoingEvents?.length >= 1) {
        return onGoingEvents[0];
      } else {
        //if there's no ongoing event & events are ended then return latest end date.

        // old - not working
        // var mostRecentDate = new Date(
        //   Math.max.apply(
        //     null,
        //     events.map((e) => {
        //       return new Date(e?.eventLocalEndDate);
        //     })
        //   )
        // );
        // var mostRecentObject = events.filter((e) => {
        //   var d = new Date(e?.eventLocalEndDate);
        //   return d.getTime() == mostRecentDate.getTime();
        // })[0];

        // new - vishal
        const res = events?.reduce((a, b) => {
          return new Date(a?.eventLocalEndDate) > new Date(b?.eventLocalEndDate)
            ? a
            : b;
        }, events[0]);

        // return mostRecentObject ? mostRecentObject : events[0];
        return res ? res : events[0];
      }
    }
  };

  handleOnProgramEventChange = (val) => {
    const { programEventDTO } = this.state;
    let event = programEventDTO?.find((x) => x.id === val?.value);
    this.props.updateUserSelectedEvent(event);
    this.setState({ selectedProgramEvent: val });
  };

  handleChangeEventSubmit = (defaultValue) => {
    const _selectedProgramEvent = defaultValue
      ? defaultValue
      : this.state.selectedProgramEvent;
    this.props.reload(_selectedProgramEvent);
  };

  getChallengeLabel = (id) => {
    if (
      ApplicationConstants.isProd &&
      ApplicationConstants?.challengeLabel_Location_Prod?.includes(id)
    ) {
      return "Location";
    } else if (ApplicationConstants?.challengeLabel_Location_QA?.includes(id)) {
      return "Location";
    } else if (ApplicationConstants.challengeLabel_Corporate_QA.includes(id)) {
      return "Corporate";
    } else {
      return "Team";
    }
  };

  render() {
    const { classes, challengeType, noTitle, width, isProgram, reload } =
      this.props;
    const { radioValue, showRunnerGroupGraph } = this.state;
    const isProgramChallenge =
      challengeType?.programChallengeType === "HYDRATION" ||
      challengeType?.programChallengeType === "NO_UNHEALTHY_EATING" ||
      challengeType?.programChallengeType === "ZERO_SUGAR";

    return (
      <Paper
        className={classes.leaderboard}
        style={{ width: width ? width : "95%" }}
        elevation={3}
      >
        {/* Heading */}
        {!noTitle && (
          <Paper variant="outlined" className={classes.leaderboardTitle}>
            <LeaderboardIcon style={{ fontSize: "50px", color: "#F79824" }} />
            <h1
              style={{
                color: "rgb(40, 131, 181)",
                textAlign: "center",
                marginTop: "20px",
              }}
            >
              LeaderBoard
            </h1>
          </Paper>
        )}
        {/* Radio button options */}

        <form>
          <FormControl
            component="fieldset"
            // error={error}
            className={classes.formControl}
          >
            <RadioGroup
              className={classes.radioStyling}
              value={radioValue}
              onChange={this.handleRadioChange}
            >
              {(challengeType?.challengeType === "BOTH" ||
                challengeType?.challengeType === "TEAM_RELAY" ||
                (challengeType?.challengeType === "TEAM" &&
                  showRunnerGroupGraph == true)) && (
                <FormControlLabel
                  value="team"
                  control={
                    <Radio
                      color="default"
                      size="small"
                      className={classes.radioStyle}
                    />
                  }
                  label={
                    this.getChallengeLabel(challengeType?.id)
                    // challengeType?.id === 507 ||
                    // challengeType?.id === 508 ||
                    // challengeType?.id === 515
                    //   ? "Corporate"
                    //   : challengeType?.id === 2473
                    //   ? "Location"
                    //   : "Team"
                  }
                  className={classes.labelStyling}
                />
              )}
              {(challengeType?.challengeType === "BOTH" ||
                challengeType?.challengeType === "TEAM_RELAY" ||
                ((challengeType?.challengeType === "INDIVIDUAL" ||
                  challengeType?.challengeType === "RELAY") &&
                  showRunnerGroupGraph == true)) && (
                <FormControlLabel
                  value="individual"
                  control={
                    <Radio
                      color="default"
                      size="small"
                      className={classes.radioStyle}
                    />
                  }
                  label="Individual"
                  className={classes.labelStyling}
                />
              )}
              {showRunnerGroupGraph && (
                <FormControlLabel
                  value="runnerGroup"
                  control={
                    <Radio
                      color="default"
                      size="small"
                      className={classes.radioStyle}
                    />
                  }
                  label="Runner Group"
                  className={classes.labelStyling}
                />
              )}
            </RadioGroup>
          </FormControl>
        </form>
        {this.props.isProgram && this.state.programEventList.length > 0 && (
          <Grid container className={classes.dropdownStyling}>
            <Grid item xs={4} md={2} style={{}} className={classes.input}>
              <Typography variant="body2">
                <span style={{ color: "#727272" }}>
                  {Properties.Label_AllEvents}
                </span>
                {/* <span style={{ color: "red" }}>*</span> */}
              </Typography>

              <MultiSelect
                value={this.state.selectedProgramEvent}
                options={this.state.programEventList}
                onChange={this.handleOnProgramEventChange}
                isSearchable={false}
                // defaultValue={programEventList[0]}
              />
            </Grid>
            <Grid item xs={4} md={2} className={classes.goBtnStyling}>
              <SubmitCancelBtn
                type="submit"
                label={"Go"}
                onClick={() => this.handleChangeEventSubmit()}
                className={classes.goBtn}
                style={{ width: "55%", margin: "auto", marginTop: "10px" }}
              />
            </Grid>
          </Grid>
        )}

        {(challengeType?.challengeType === undefined ||
          challengeType?.challengeType === "INDIVIDUAL" ||
          radioValue === "individual") &&
          !isProgramChallenge && (
            <IndividualBoard
              defaultStairStepper={this.props.defaultStairStepper}
              isProgram={isProgram}
              isOk={true}
              getSearchedMapResults={this.props.getSearchedMapResults}
              challengeType={challengeType?.challengeType}
              categoryDTO={this.state.categoryDTO}
              reload={reload}
              radioValue={radioValue}
            />
          )}

        {isProgramChallenge && (
          <BinaryResponseBoard
            challengeType={challengeType}
            isProgram={isProgram}
            reload={reload}
          />
        )}

        {((challengeType?.challengeType === "TEAM" && radioValue === "team") ||
          radioValue === "team") && (
          <TeamBoard
            isProgram={isProgram}
            getSearchedMapResults={this.props.getSearchedMapResults}
            radioValue={radioValue}
            challengeType={challengeType}

            reload={reload}
          />
        )}
        {radioValue === "runnerGroup" && showRunnerGroupGraph && (
          <TeamBoard
            getSearchedMapResults={this.props.getSearchedMapResults}
            radioValue={radioValue}
          />
          // </>
        )}
      </Paper>
    );
  }
}

function mapStateToProps(state) {
  return {
    userSelectedProgram: state.user.userSelectedProgram,
    userSelectedEvent: state.user.userSelectedEvent,
  };
}

export default compose(
  withStyles(styles, { withTheme: true }),
  withRouter,
  connect(mapStateToProps, {
    updateUserSelectedProgram,
    updateUserSelectedEvent,
  })
)(Leaderboard);
