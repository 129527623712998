import http from "./httpService";
import { loading } from "../components/common/Spinner";
import Snackbar from "../components/common/Snackbar";
import Properties from "../properties/Properties";
import moment from "moment";
import ApplicationUtil from "../utils/ApplicationUtil";

const API_URL_FILE = http.API_URL();

export function getProgramChallengeQueOfTheDay(programChallengeId, date) {
  loading(true);

  let runner_token = ApplicationUtil.getRunnerToken();
  let runner = ApplicationUtil.getRunnerDetails();

  let token = runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/program/challenge/question/${programChallengeId}/${runner?.runnerId}?viewDate=${date}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function modifyProgramChallengeParticipant(
  requestParams = {},
  customLoading = true
) {
  let token = ApplicationUtil.getRunnerToken();
  let runner = ApplicationUtil.getRunnerDetails();
  customLoading && loading(customLoading);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };
  requestParams.runnerId = runner?.runnerId;
  let url = API_URL_FILE + "api/v1/program/challenge/participant/update";

  return new Promise(function (resolve, reject) {
    http
      .put(url, requestParams, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          customLoading && loading(false);
          resolve(res);
        }
      })
      .catch((error) => {
        customLoading && loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

export function submitResponse(data) {
  loading(true);
  let runner_token = ApplicationUtil.getRunnerToken();

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: "Bearer " + runner_token,
    },
  };
  let url =
    API_URL_FILE + "api/v1/program/challenge/question/participant/response";
  var formData = new FormData();

  formData.append("questionId", data.questionId);
  formData.append("programChallengeId", data.programChallengeId);
  formData.append("response", data.response);
  formData.append("runnerId", data.runnerId);
  formData.append("viewDate", data.viewDate);
  if (data.lifeStyle || data.currentClimate) {
    formData.append("lifeStyle", data.lifeStyle);
    formData.append("currentClimate", data.currentClimate);
  }
  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error?.response
              ? error.response?.data
                ? error.response.data?.error?.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
export function saveUserMatrics(requestParams) {
  let runner_token = ApplicationUtil.getRunnerToken();
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${runner_token}`,
    },
  };
  let url = API_URL_FILE + "api/v1/product/user/click";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {})
      .catch((error) => {
        reject(error);
      });
  });
}

//Current tab is shown for logout user -- here public and openProgram is shown
export function getPublicProgram(requestParams) {
  loading(true);

  let axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  let url =
    API_URL_FILE +
    "api/v1/public/program/list" +
    "?" +
    "&eventState=" +
    requestParams.eventState +
    "&eventScope=" +
    requestParams.eventScope;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });

}




/**
 * Email content creation Apis
 */


// create
export function createEmailReminderContent(requestParams) {
  loading(true);
  let token =ApplicationUtil.getSupportToken();;
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
  };


  let url = API_URL_FILE + "api/v1/email/subject/content/config";

  return new Promise(function (resolve, reject) {
    http
      .post(url, requestParams, axiosConfig)
      .then((res) => {
        if (res?.data?.success?.code === "409") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: res?.data?.success?.verbose
              ? res?.data?.success?.verbose
              : Properties.Something_went_wrong,
          });
          reject(res);
        } else {
          resolve(res);
        }
        loading(false);
      }
    )
      .catch((error) => {
        reject(error);
      });
  });
}


//Edit 
// delete
export function deleteActivity(eventId, runnerId, activityIds) {
  let token = ApplicationUtil.getSupportToken();
 
  loading(true);
  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = API_URL_FILE + "api/v1/runner/activity/" + eventId + "/" + runnerId;
  let arr = activityIds;
  arr = arr.toString();
  var formData = new FormData();
  formData.append("runnerActivityId", arr);

  return new Promise(function (resolve, reject) {
    http
      .delete(url, {
        headers: { Authorization: "Bearer " + token },
        data: formData,
      })
      .then((res) => {
        if (res.status === 200) {
          loading(false);

          if (res?.data?.code === "200") {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Activity Deleted",
            });
          } else if (res?.data?.success?.code === "409") {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.success?.verbose
                ? res?.data?.success?.verbose
                : Properties.Something_went_wrong,
            });
          }
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}
// fetch list

export function getEmailReminderContents(challengeType) {
  loading(true);

  let token =ApplicationUtil.getSupportToken();;


  let axiosConfig = {
    headers: {
      "Content-Type": "application/json;",
      "Access-Control-Allow-Origin": "*",
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

  let url = `${API_URL_FILE}api/v1/email/subject/content/info?programChallengeType=${challengeType}`;

  return new Promise(function (resolve, reject) {
    http
      .get(url, axiosConfig)
      .then((res) => {
        if (res.status === 200) {
          loading(false);
          resolve(res);
        } else {
          loading(false);
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
        }
        reject(error);
      });
  });
}

//Record-response image upload and delete handled.  
export function uploadModifyAndDeleteImage(requestedParams) {
  loading(true);
  let supportOrganizer_token = ApplicationUtil.getSupportToken();
  let runner_token = ApplicationUtil.getRunnerToken();
  let token = window.location.href.includes("/organizer")
    ? supportOrganizer_token
    : runner_token;

  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    },
  };
  let url = API_URL_FILE + "api/v1/program/challenge/photo/upload";

  var formData = new FormData();
  if (requestedParams.file) {
    formData.append("file", requestedParams.file);
  }

  formData.append("viewDate", requestedParams.viewDate);
  formData.append("runnerId", requestedParams.runnerId);
  formData.append("id", requestedParams.id);

  return new Promise(function (resolve, reject) {
    http
      .post(url, formData, axiosConfig)
      .then((res) => {
        loading(false);
        
        if (res.status === 200) {
          if (res?.data) {
            Snackbar.show({
              variant: "success",
              isAutoHide: true,
              preventDuplicate: true,
              message: "Photo updated successfully",
            });
            resolve(res);
          } else {
            Snackbar.show({
              variant: "error",
              isAutoHide: true,
              preventDuplicate: true,
              message: res?.data?.verbose,
            });
            reject(res);
          }
        } else {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: Properties.Something_went_wrong,
          });
          reject(res);
        }
      })
      .catch((error) => {
        loading(false);
        if (error !== "") {
          Snackbar.show({
            variant: "error",
            isAutoHide: true,
            preventDuplicate: true,
            message: error.response
              ? error.response.data
                ? error.response.data.error.verbose
                : Properties.Something_went_wrong
              : Properties.Something_went_wrong,
          });
        }

        reject(error);
      });
  });
}

